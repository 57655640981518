@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  background: #0f203d;
}

div[display="table-cell"] {
  background-color: red !important;
}

.apexcharts-menu {
  background-color: black !important;
  color: #fff;
}
.apexcharts-menu-item:hover {
  background-color: #2563eb !important;
}

::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0.1);
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #2563eb;
  border-radius: 5px;
  height: 5px;
}
