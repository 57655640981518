.menu-item-selection {
  position: absolute;
  top: 0;
  left: 50%;
  width: calc(100% - 40px);
  border-radius: 8px;
  /* background-color: #4299e1; */
  z-index: -1;
  transform: translateX(-50%);
  transition: 0.3s ease-in-out;
}

