.banner {
  padding: 20px;
  text-align: center;
  transform: translateY(100%);
  transition: all 0.7s ease-in-out;
  opacity: 0;
  backdrop-filter: blur(16px);
  -webkit-backdrop-filter: blur(16px);
  border-radius: 6px;
  /* background-color: rgba(0, 0, 0, 0.4) */
}

.banner.show {
  transform: translateY(0%);
  animation: slide-up-bounce 0.7s;
  opacity: 1;
}

@keyframes slide-up-bounce {
  0% {
    transform: translateY(100%);
  }

  60% {
    transform: translateY(-5%);
  }

  80% {
    transform: translateY(5%);
  }

  100% {
    transform: translateY(0%);
  }
}

.database_explorer {
  display: flex;
  width: 100%;
  height: 200px; /* Adjust the max height as needed */
  overflow: hidden;
}

.database_list,
.schema_list,
.table_list {
  width: 20%;
  max-height: 100%;
  border-right: 1px solid #ccc;
  overflow-y: auto;
}

.database_list div,
.schema_list div,
.table_list div {
  cursor: pointer;
  padding: 5px;
}

.database_list div:hover,
.schema_list div:hover,
.table_list div:hover {
  background-color: "#563eb";
}

.column_table {
  width: 40%;
  max-height: 100%;
  border-collapse: collapse;
  overflow-y: scroll;
}

.column_table th,
.column_table td {
  border: 1px solid #ccc;
  padding: 5px;
}
