.teamTable--body > tr{
   background: transparent;
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.3px);
    -webkit-backdrop-filter: blur(5.3px);
}

.teamTable--body tr td:first-child {
    border-top-left-radius: 12px;
     border-bottom-left-radius: 12px;
}
.teamTable--body tr td:last-child {
     border-top-right-radius: 12px;
     border-bottom-right-radius: 12px;
}

 .teamTable--head th,.teamTable--body td{
    text-align: center !important;
  
}
 .teamTable--head th{
    text-align: center !important;
    color: #3B82F6;
        border-bottom: transparent;
  
}
.teamTable--body td{
border: 0.5px solid #4A4A4A;
}


/* .teamTable--body tr td:first-child { 
    border-top-left-radius: 10px; 
    border-bottom-left-radius: 10px ;

}

.teamTable--body tr td:last-child{ 
    border-top-right-radius: 10px; 
    border-bottom-right-radius: 10px;

}  */

.glass{
    background:  linear-gradient(250.79deg, rgba(255, 255, 255, 0) 0%, rgba(154, 154, 154, 1) 100%);
    border-radius: 7px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.3px);
    -webkit-backdrop-filter: blur(5.3px);
    border-image-source: linear-gradient(252.24deg, #FFFFFF 4.74%, rgba(255, 255, 255, 0) 93.73%);
}

.icon{
    margin: 0 auto;
    transition: transform 0.3s ease-in-out;
}

.icon:hover{
    transform: scale(1.1);
}
.blueButton{
  background-color: #2563eb;
  color: white;
  cursor: pointer;
  border-color: transparent;
    border-style: solid;
    border-radius: 10px;
    padding: 12px;
    padding-left: 25px;
padding-right: 25px;
transition: 0.3s ease;
color: white;
font-weight: 700;


}
.blueButton:hover{
      background-color: #1849b4;
}


.blackDiv{
  background: transparent;
  color: #E2E8F0;
border: 0.5px solid #4A4A4A;
 outline: none;
 padding: 10px;
border-radius: 7px;
 transition: 0.3s ease;
}

.blackButton{
  background: transparent;
  color: #E2E8F0;
border: 0.5px solid #4A4A4A;
 outline: none;
 padding: 10px;
border-radius: 7px;
 cursor: pointer;

}

