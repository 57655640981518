.linear {
  [cmdk-root] {
    max-width: 640px;
    width: 100%;
    background: linear-gradient(136.61deg, rgb(39, 40, 43) 13.72%, rgb(45, 46, 49) 74.3%);

    border-radius: 8px;
    overflow: hidden;
    padding: 0;
    font-family: var(--font-sans);
    box-shadow: var(--cmdk-shadow);
  }

  [cmdk-input] {
    font-family: var(--font-sans);
    border: none;
    width: 100%;
    font-size: 18px;
    padding: 20px;
    outline: none;
    background: var(--bg);
    color: var(--gray12);
    border-bottom: 1px solid var(--gray6);
    border-radius: 0;
    caret-color: #6e5ed2;
    margin: 0;

    &::placeholder {
      color: var(--gray9);
    }
  }

  [cmdk-item] {
    content-visibility: auto;

    cursor: pointer;
    height: 48px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 0 16px;
    color: var(--gray12);
    user-select: none;
    will-change: background, color;
    transition: all 150ms ease;
    transition-property: none;
    position: relative;

    &[aria-selected='true'] {
      background-color: #232323;

      svg {
        color: var(--gray12);
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        z-index: 123;
        width: 3px;
        height: 100%;
        background: #5f6ad2;
      }
    }

    &[aria-disabled='true'] {
      color: var(--gray8);
      cursor: not-allowed;
    }

    &:active {
      transition-property: background;
      background: var(--gray4);
    }

    & + [cmdk-item] {
      margin-top: 4px;
    }

    svg {
      width: 16px;
      height: 16px;
      color: var(--gray10);
    }
  }

  [cmdk-list] {
    height: min(300px, var(--cmdk-list-height));
    max-height: 400px;
    overflow: auto;
    overscroll-behavior: contain;
    transition: 100ms ease;
    transition-property: height;
  }

  [cmdk-group-heading] {
    user-select: none;
    font-size: 12px;
    color: var(--gray11);
    padding: 0 8px;
    display: flex;
    align-items: center;
  }

  [cmdk-empty] {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 64px;
    white-space: pre-wrap;
    color: var(--gray11);
  }

  .cmdk-item-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1; /* Add this to make the text container grow and shrink with the available space */
  }
}