.autocomplete {
  position: relative;
}
.glass {
  background: linear-gradient(
    250.79deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(154, 154, 154, 1) 100%
  );
  border-radius: 7px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.3px);
  -webkit-backdrop-filter: blur(5.3px);
  border: 1px solid #191919;
}

.dropdown {
  color: white;
}

.dropdown > div {
  background: transparent;
  border-radius: 7px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.3px);
  -webkit-backdrop-filter: blur(5.3px);
}

.chatInput {
  background: red;
}

.blackDiv {
  background: transparent;
  color: #e2e8f0;
  border: 0.5px solid #4a4a4a;
  outline: none;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
  transition: 0.3s ease;
}
.bgclr {
  background-color: #181818;
}

.chakra-checkbox__control {
  background: white;
}
.chakra-checkbox__control[data-checked] {
  background: white !important;
  color: #2563eb !important;
}

.scrollBarChat {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.scrollBarChat::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
input::file-selector-button {
  background-color: black;
  border: 0.5px solid #4a4a4a;
  border-radius: 7px;
  color: white;
  padding: 2px;
}
