.formContainer {
  background-color: #a7bcff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
#chartContainer .apexcharts-tooltip {
  color: #fff;
}
.apexcharts-tooltip.apexcharts-theme-light {
  background: black !important;
  color: white !important;
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: black !important;
}
#chartContainer .apexcharts-tooltip .apexcharts-tooltip-series-group.active {
  background: #232b2b !important;
}
.formContainer .formWrapper {
  background-color: white;
  padding: 20px 60px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.ps-submenu-content {
  background: black !important;
  color: white !important;
}
.ps-menu-button:focus {
  background: #3c82f6;
  color: white;
}
.formContainer .formWrapper .logo {
  color: #5d5b8d;
  font-weight: bold;
  font-size: 24px;
}
.formContainer .formWrapper .title {
  color: #5d5b8d;
  font-size: 12px;
}
.formContainer .formWrapper form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.formContainer .formWrapper form input {
  padding: 15px;
  border: none;
  width: 250px;
  border-bottom: 1px solid #a7bcff;
}
.formContainer .formWrapper form input::placeholder {
  color: #afafaf;
}
.formContainer .formWrapper form button {
  background-color: #7b96ec;
  color: white;
  padding: 10px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}
.formContainer .formWrapper form label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #8da4f1;
  font-size: 12px;
  cursor: pointer;
}
.formContainer .formWrapper form label img {
  width: 32px;
}
.formContainer .formWrapper p {
  color: white;
  font-size: 12px;
  margin-top: 10px;
}

.sidebar {
  flex: 1;
  background-color: #3e3c61;
  position: relative;
}
.sidebar .navbar {
  display: flex;
  align-items: center;
  background-color: #2f2d52;
  height: 50px;
  padding: 10px;
  justify-content: space-between;
  color: #ddddf7;
}
.sidebar .navbar .logo {
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .sidebar .navbar .logo {
    display: none;
  }
}
.sidebar .navbar .user {
  display: flex;
  gap: 10px;
}
.sidebar .navbar .user img {
  background-color: #ddddf7;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
}
.sidebar .navbar .user button {
  background-color: #5d5b8d;
  color: #ddddf7;
  font-size: 10px;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .sidebar .navbar .user button {
    position: absolute;
    bottom: 10px;
  }
}
.sidebar .search {
  border-bottom: 1px solid gray;
}
.sidebar .search .searchForm {
  padding: 10px;
}
.sidebar .search .searchForm input {
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
}
.sidebar .search .searchForm input::placeholder {
  color: lightgray;
}
.sidebar .userChat {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  cursor: pointer;
}
.sidebar .userChat:hover {
  background-color: #2f2d52;
}
.sidebar .userChat img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.sidebar .userChat .userChatInfo span {
  font-size: 18px;
  font-weight: 500;
}
.sidebar .userChat .userChatInfo p {
  font-size: 14px;
  color: lightgray;
}
.chat {
  flex: 2;
  border-radius: 30px;
}
.chat .chatInfo {
  height: 50px;
  background-color: #3c82f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: lightgray;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}
.chat .chatIcons {
  display: flex;
  gap: 10px;
}
.chat .chatIcons img {
  height: 24px;
  cursor: pointer;
}

.chat .messages {
  background-color: white;
  padding: 2px 0 30px 0;
  height: calc(100vh - 180px);
  overflow: auto;
  overflow-x: hidden;
  border-radius: 8px;
}
.chat .messages .message {
  display: flex;
  /* margin-bottom: 20px; */
  width: 100%;
  align-items: center;
  padding: 0 14px;
}
.chat .messages .message .messageInfo {
  display: flex;
  flex-direction: column;
  color: black;
  font-weight: 300;
}
.chat .messages .message .messageInfo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.chat .messages .message .messageContent {
  display: flex;
  flex-direction: column;
}
.chat .messages .message .messageContent img {
  width: 50%;
}
.chat .messages .message.owner {
  background-color: #f7f7f8;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}

.chat .messages .message.owner .messageContent p {
  /* background-color: #3c82f6; */
  /* color: white; */
  border-radius: 10px 0px 10px 10px;
}
.chat .input {
  height: 50px;
  background-color: white;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chat .input input {
  width: 100%;
  border: none;
  outline: none;
  color: #2f2d52;
  font-size: 18px;
}
.attached {
  border: 2px solid #3c82f6;
  border-radius: 6px;
  padding: 0px;
  border-style: dashed;
}
.chatInfo span {
  color: white;
  margin-left: 28px;
  margin-top: 1px;
  font-size: 1.2rem;
}
.chat .input input::placeholder {
  color: lightgray;
}
.chat .input .send {
  display: flex;
  align-items: center;
  gap: 10px;
}
.chat .input .send img {
  height: 24px;
  cursor: pointer;
}
.chat .input .send button {
  border: none;
  padding: 6px 8px;
  color: white;
  cursor: pointer;
  border-radius: 6px;
}
body {
  background: #f5f7fb !important;
}

:root {
  --circle-size: clamp(1.5rem, 5vw, 3rem);
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
}

.c-stepper {
  display: flex;
}

.c-stepper__item {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: center;
}
.c-stepper__item:before {
  --size: 3rem;
  content: "";
  display: block;
  width: var(--circle-size);
  height: var(--circle-size);
  border-radius: 50%;
  background-color: lightgrey;
  background-color: #3c82f6;
  opacity: 0.5;
  margin: 0 auto 1rem;
}
.c-stepper__item:not(:last-child):after {
  content: "";
  position: relative;
  top: calc(var(--circle-size) / 2);
  width: calc(100% - var(--circle-size) - calc(var(--spacing) * 2));
  left: calc(50% + calc(var(--circle-size) / 2 + var(--spacing)));
  height: 2px;
  background-color: #e0e0e0;
  order: -1;
}

.c-stepper__title {
  font-weight: bold;
  font-size: clamp(1rem, 4vw, 1.25rem);
  margin-bottom: 0.5rem;
}

.c-stepper__desc {
  color: grey;
  font-size: clamp(0.85rem, 2vw, 1rem);
  padding-left: var(--spacing);
  padding-right: var(--spacing);
}

.chat-input-loading-tooltip-container {
  position: relative;
  bottom: 88px;
  right: 78px;
  display: inline-block;
}

.chat-input-loading-tooltip-container .tooltip-content {
  position: absolute;
  width: 200px;
  height: 50px;
  line-height: 20px;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  background: rgb(255, 255, 255);
  border: 4px solid rgb(255, 255, 255);
  border-radius: 5px;
  text-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 0px;
}

.chat-input-loading-tooltip-container .tooltip-content:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
  top: 46px;
  left: 50%;
  margin-left: -10px;
}

.ka-cell {
  padding: 8px!important;
  line-height: 16px!important;
}
