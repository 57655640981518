.glass {
  background: linear-gradient(
    250.79deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(154, 154, 154, 1) 100%
  );
  border-radius: 7px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.3px);
  -webkit-backdrop-filter: blur(5.3px);
  border: 1px solid #191919;
}

.dropdown {
  color: white;
}

.dropdown > div {
  background: transparent;
  border-radius: 7px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.3px);
  -webkit-backdrop-filter: blur(5.3px);
}

.search {
  position: absolute;
  width: 25px;
  left: 750px;
  top: 12px;
  cursor: pointer;
}
.blueButton {
  background-color: #2563eb;
  color: white;
  cursor: pointer;
  border-color: transparent;
  border-style: solid;
  border-radius: 10px;
  padding: 12px;
  padding-left: 25px;
  padding-right: 25px;
  transition: 0.3s ease;
  color: white;
  font-weight: 700;
}
.blueButton:hover {
  background-color: #1849b4;
}

.tableHeading {
  color: #2563eb;
}
.blackButton {
  background: transparent;
  color: #e2e8f0;
  border: 0.5px solid #4a4a4a;
  outline: none;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
  transition: 0.3s ease;
}
.blackButton:hover {
  color: white;
  scale: 102%;
}
.blackDiv {
  background: transparent;
  color: #e2e8f0;
  border: 0.5px solid #4a4a4a;
  outline: none;
  padding: 10px;
  border-radius: 7px;
  transition: 0.3s ease;
}
.chakra-modal__content {
  background-color: black !important;
}
