.projectsTable {
  border-collapse: separate !important;
  border-spacing: 0 20px;
}

.projectsTable--body > tr {
  background: transparent;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.3px);
  -webkit-backdrop-filter: blur(5.3px);
}

.projectsTable--body tr td {
  border: 0.5px solid #4a4a4a;

  color: white;
}
.projectsTable--body tr td:hover {
  border: 0.5px solid #2563eb;
  border-left: 0.5px solid #2563eb !important;
  color: white;
}

.projectsTable--head th {
  color: #3b82f6;
  border-bottom: transparent;
}

.projectsTable--body tr td:first-child {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0.5px solid #4a4a4a;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.projectsTable--body tr td:last-child {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0.5px solid #4a4a4a;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.glass {
  background: linear-gradient(
    250.79deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(154, 154, 154, 1) 100%
  );
  border-radius: 7px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.3px);
  -webkit-backdrop-filter: blur(5.3px);
  border: 1px solid #191919;
  transition: transform 0.3s ease-in-out;
}

.glass:hover {
  transform: scale(1.1);
}

.icon {
  width: 30px;
  color: red;
}
.blueButton {
  background-color: #2563eb;
  color: white;
  cursor: pointer;
  border-color: transparent;
  border-style: solid;
  border-radius: 10px;
  padding: 12px;
  padding-left: 25px;
  padding-right: 25px;
  transition: 0.3s ease;
  color: white;
  font-weight: 700;
}
.blueButton:hover {
  background-color: #1849b4;
}
.tableHeading {
  color: #2563eb;
}

.blackDiv {
  background: transparent;
  color: #e2e8f0;
  border: 0.5px solid #4a4a4a;
  outline: none;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
  transition: 0.3s ease;
}
.blackButton {
  background: transparent;
  color: #e2e8f0;
  border: 0.5px solid #4a4a4a;
  outline: none;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
}
