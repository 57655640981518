.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: -70px;
  width: 100%;
}

.item {
  border: solid white 0.5px;
  color: white;
  opacity: 0;
  right: 0;
  position: absolute;
  transition: all 0.5s ease-in-out;

}
.item-0 {
  transition-delay: 0.05s;
}

.item-1 {
  transition-delay: 0.1s;
}

.item-2 {
  transition-delay: 0.15s;
}

.item-3 {
  transition-delay: 0.2s;
}

.item-4 {
  transition-delay: 0.25s;
}

.show .item {
  opacity: 1;
}

.show .item-0 {
  transform: translateY(0);
}

.show .item-1 {
  transform: translateY(-70px);
}

.show .item-2 {
  transform: translateY(-140px);
}

.show .item-3 {
  transform: translateY(-210px);
}

.show .item-4 {
  transform: translateY(-260px);
}
