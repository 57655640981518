.scrollContainer {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.scrollContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.bgz-white {
  color: white !important;
}
.d-flex {
  display: flex;
}
.spaced {
  margin-right: 12px;
}
